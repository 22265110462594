import React from "react"
import PropTypes from "prop-types"
import './styles.scss'

const PageTitle = ({ title }) => {


  return (
    <h1 className='subtitle fancy'>
      <span>
        {title}
      </span>
    </h1>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
}

PageTitle.defaultProps = {
  title: '',
}

export default PageTitle
