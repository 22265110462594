import React from "react"
import { Link } from "gatsby"
import useStyles from '../../styles/experienceStyles'

import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';


const Experience = ({ experience }) => {
  const classes = useStyles()

  const experienceImage = experience?.img.childImageSharp.fluid.originalImg
  const title = experience?.title
  const subtitle = experience?.subtitle
  const description = experience?.description
  const id = experience?.id

  return (
    <Grid item xs={12} md={6}>
      {description ?
        <Link to={`/experience-item-page?id=${id}`}>
          <Card className={classes.card}>
            <CardActionArea>
            <CardMedia>
              <img src={experienceImage} alt={subtitle} title={title} className={classes.cardMedia}/>
            </CardMedia>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" component="h2">
                  {title}
                </Typography>
                <Typography gutterBottom variant="h6" component="h3">
                  {subtitle}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
        : <Card className={classes.card}>
          <CardActionArea>
            <CardMedia>
            <img src={experienceImage} alt={subtitle} title={title} className={classes.cardMedia}/>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h6" component="h2">
                {title}
              </Typography>
              <Typography gutterBottom variant="h6" component="h3">
                {subtitle}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      }
    </Grid>
  )

}

export default Experience