import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    card: {
      height: 300,
      margin: '0 auto 2rem'
    },
    cardMedia: {
      height: 300,
      width: '100%'
    },
    cardContent: {
      padding: '10px',
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: '#ffffffCC',
      textAlign: 'center',
      height: 'calc(61% - 20px)',
      '& h2': {
        fontSize: '1.3rem',
        color: theme.palette.primary.main,
        lineHeight: '1.4rem',
      },
      '& h3': {
        fontSize: '1rem',
        lineHeight: '1.3rem',
      },
    },
    cardActions: {
      justifyContent: 'center'
    },
    actionButton: {
      width: 300
    },
    content: {
      lineHeight:'1.5rem'
    }
  })
});

export default useStyles