import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from '../components/PageTitle'
import SEO from '../components/seo'

import Experience from '../components/Experience'
import { Grid } from '@material-ui/core'


const Experiences = () => {
  const query = useStaticQuery(graphql`
{
  allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "experiences"}}}}) {
    nodes {
      childMarkdownRemark {
        frontmatter {
          title
          seoDescription
          seoKeywords
          seoTitle
          references {
            img {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }, 
            title,
            subtitle
            id
            description
          }
        }
      }
    }
  }
}
`)



  const experiences = query.allFile.nodes[0].childMarkdownRemark.frontmatter.references
  const pageTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.title
  const seoDescription = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoDescription
  const seoKeywords = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoKeywords
  const seoTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoTitle

  return (
    <Layout>
      <SEO title={seoTitle !== undefined ? seoTitle : pageTitle} description={seoDescription !== undefined ? seoDescription : null} keywords={seoKeywords !== undefined ? seoKeywords : null}/>
      <PageTitle title={pageTitle} />
      <Grid container spacing={4}>
        {experiences.map((experience) => (
          <Experience experience={experience} profileLink key={experience.id} />
        ))}
      </Grid>
    </Layout>
  )
}
export default Experiences
